import React, { FC, useEffect, useRef, useState } from 'react';
import '../styles/ImagesList.scss';
import { getBase64 } from '../../../utils/getBase64';
import { toastr } from 'react-redux-toastr';
import { apiRequest } from '../../../utils';
import { Button, Col, Row } from 'reactstrap';

interface IconsProductListProps {
  setValues: (values: any) => void;
  imageUrl: string;
}

const IconsProductList: FC<IconsProductListProps> = ({ setValues, imageUrl }) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [showIconsList, setShowIconsList] = useState<boolean>(false);
  const [deleteIconInfo, setDeleteIconInfo] = useState<{ Key: string; Bucket: string }>({
    Key: '',
    Bucket: '',
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [icons, setIcons] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const onClickSetImageLink = (link: string, Key: string, Bucket: string) => {
    const sanitizedLink = link.replace(/ /g, '%20');

    setValues((prevValues: any) => ({
      ...prevValues,
      imageUrl: sanitizedLink,
    }));

    setDeleteIconInfo({ Key, Bucket });
  };

  const onImageUpload = async (file: File, setUploading: (state: boolean) => void) => {
    const abortController = new AbortController();
    try {
      setUploading(true);
      const base64File = (await getBase64(file)) as string;

      if (!base64File) {
        toastr.error('Error', `Error while uploading ${file.name}`);
        setUploading(false);
        return;
      }

      const { data } = await apiRequest(`/product/upload-image`, {
        method: 'POST',
        data: JSON.stringify({ file: base64File, filename: file.name, filetype: file.type }),
        signal: abortController.signal,
      });

      const { message, imageUrl } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg));
        } else {
          toastr.error('Error', message);
        }
        setUploading(false);
        return;
      }

      toastr.success('Success', 'Image uploaded successfully');
      const updatedIcons = icons ? [...icons, { url: imageUrl }] : [{ url: imageUrl }];
      setValues((prevValues: any) => ({
        ...prevValues,
        icons: updatedIcons,
      }));
      const response = await apiRequest('/product/icons', { method: 'GET', data: [] });
      setIcons(response.data);
      setUploading(false);
    } catch (err) {
      console.error('UPLOAD_CRAFTSMAN_IMAGE error', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request');
      } else {
        toastr.error('Error', 'Failed to upload image');
      }
      abortController.abort();
      setUploading(false);
    }
  };

  const handleFileSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      await onImageUpload(file, setUploading);
    } else {
      toastr.error('Error', 'No file selected');
    }
  };

  const onClickDeleteIcon = async () => {
    const abortController = new AbortController();
    setUploading(true);
    try {
      const { data } = await apiRequest(`/product/delete-image`, {
        method: 'POST',
        data: JSON.stringify({
          Key: deleteIconInfo.Key,
          Bucket: deleteIconInfo.Bucket,
        }),
        signal: abortController.signal,
      });

      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg));
        } else {
          toastr.error('Error', message);
        }
        setUploading(false);
        return;
      }
      const response = await apiRequest('/product/icons', { method: 'GET', data: [] });
      setIcons(response.data);
      setDeleteIconInfo({ Key: '', Bucket: '' });
    } catch (err) {
      console.error('UPLOAD_CRAFTSMAN_IMAGE error', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request');
      } else {
        toastr.error('Error', 'Failed to upload image');
      }
      abortController.abort();
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    const fetchIcons = async () => {
      setLoading(true);
      try {
        const iconsData = await getIcons();
        setIcons(iconsData);
      } catch (error) {
        console.error('Error fetching icons:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchIcons();
  }, []);

  const getIcons = async () => {
    const response = await apiRequest('/product/icons', { method: 'GET', data: [] });
    return response.data;
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Row className="w-100 mx-auto">
      <Button color="primary" type="button" className="btn-md" onClick={() => setShowIconsList(!showIconsList)}>
        Icons List
      </Button>
      {showIconsList && (
        <div className="mt-3 w-100">
          <ul className="icon-list">
            {icons?.map((icon, index) => {
              const sanitizedIconUrl = icon.url.replace(/ /g, '%20');
              const sanitizedImageUrl = imageUrl?.replace(/ /g, '%20');

              return (
                <li
                  key={index}
                  onClick={() => onClickSetImageLink(icon.url, icon.Key, icon.Bucket)}
                  className={sanitizedIconUrl === sanitizedImageUrl ? 'active-icon' : ''}
                >
                  <img src={icon.url} alt="product-icon" className="icon-image" />
                </li>
              );
            })}
          </ul>
          <Col className="d-flex gap-2 align-items-center">
            <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileSelection} />
            <button type="button" className="add-icon-button" onClick={triggerFileInput} disabled={uploading}>
              {uploading ? 'Uploading...' : 'Add More Icons'}
            </button>
            <button type="button" className="add-icon-button" onClick={onClickDeleteIcon}>
              {uploading ? 'Uploading...' : 'Delete Icon'}
            </button>
            {deleteIconInfo.Key && <p>Icon Selected For Delete</p>}
          </Col>
        </div>
      )}
    </Row>
  );
};

export default IconsProductList;
