import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Col, List, ListInlineItem, Row, Spinner } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { CustomInput } from '../../../components/Input/CustomInput';
import { apiRequest, TOAST_IMPORTANT_TIME_OUT_MS } from '../../../utils';
import { ProductCategory } from '../../../types';
import Tooltip from '../../../components/Tooltip';
import IconsProductList from './IconsProductList';

const ProductTypeSchema = yup
  .object({
    _id: yup.string(),
    title: yup.object({
      en: yup.string().required('English title is required.'),
      de: yup.string().required('German title is required.'),
    }),
    header: yup.object({
      en: yup.string().required('English header is required.'),
      de: yup.string().required('German header is required.'),
    }),
    metadescription: yup.object({
      en: yup.string().required('English header is required.'),
      de: yup.string().required('German header is required.'),
    }),
    seo: yup.object({
      en: yup.string().required('English header is required.'),
      de: yup.string().required('German header is required.'),
    }),
    imageUrl: yup.string().required('This field is required.'),
    description: yup.object({
      en: yup.string().max(3000, 'Maximum 3000 characters').required('English description is required.'),
      de: yup.string().max(3000, 'Maximum 3000 characters').required('German description is required.'),
    }),
    category: yup.mixed().required('This field is required.'),
    displayRank: yup.number(),
  })
  .defined();
type ProductType = yup.InferType<typeof ProductTypeSchema>;

const CreateProductTypeForm = ({ initialValues, productCategories, onCreation, isViewOnly = false }: CreateProductTypeFormProps) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<ProductType>({
    _id: '',
    title: {
      en: '',
      de: '',
    },
    header: {
      en: '',
      de: '',
    },
    imageUrl: '',
    description: {
      en: '',
      de: '',
    },
    metadescription: {
      en: '',
      de: '',
    },
    seo: {
      en: '',
      de: '',
    },
    category: '',
    displayRank: 0,
  });

  useEffect(() => {
    if (initialValues) {
      setValues(initialValues);
    }
  }, [initialValues]);

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  const onSubmit = async (values: ProductType, action: any) => {
    enableLoading();
    let response;
    const valuesCopy = { ...values };
    delete valuesCopy._id;

    if (initialValues && initialValues._id) {
      response = await updateRequest(initialValues._id, JSON.stringify(valuesCopy));
    } else {
      response = await createRequest(JSON.stringify(valuesCopy));
    }

    const { message, ...data } = response.data;

    if (message) {
      if (Array.isArray(message)) {
        message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
      } else {
        toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      action.setSubmitting(false);
      return disableLoading();
    }

    await onCreation(!initialValues || !initialValues._id ? data._id : null);

    toastr.success('Success', `Type ${initialValues && initialValues._id ? 'updated' : 'created'}`);
    return disableLoading();
  };

  const updateRequest = async (_id: string, data: string) => await apiRequest(`/product/type/${_id}`, { method: 'PUT', data });
  const createRequest = async (data: string) => await apiRequest('/product/type', { method: 'POST', data });

  return (
    <Formik enableReinitialize initialValues={values} validationSchema={ProductTypeSchema} onSubmit={onSubmit}>
      {(formik) => {
        const onProductCategorySelect = (_id: string) => {
          formik.setFieldValue('category', _id);
        };

        return (
          <div className="d-flex flex-column gap-4">
            <Row>
              <Col>
                <CustomInput
                  label="Title En"
                  inputProps={{
                    id: 'title.en',
                    name: 'title.en',
                    type: 'text',
                    value: formik.values.title?.en,
                    onChange: formik.handleChange,
                    disabled: isViewOnly,
                  }}
                  addon={
                    <Tooltip placement="bottom" target="product-category-title-example">
                      <img src="/images/product/category/product-category-title-example.png" alt="Example" width={400} height={250} />
                    </Tooltip>
                  }
                  error={formik.touched.title?.en && formik.errors.title?.en ? formik.errors.title?.en : ''}
                />
              </Col>
              <Col>
                <CustomInput
                  label="Title De"
                  inputProps={{
                    id: 'title.de',
                    name: 'title.de',
                    type: 'text',
                    value: formik.values.title?.de,
                    onChange: formik.handleChange,
                    disabled: isViewOnly,
                  }}
                  addon={
                    <Tooltip placement="bottom" target="product-category-title-example">
                      <img src="/images/product/category/product-category-title-example.png" alt="Example" width={400} height={250} />
                    </Tooltip>
                  }
                  error={formik.touched.title?.de && formik.errors.title?.de ? formik.errors.title?.de : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomInput
                  label="Header En"
                  inputProps={{
                    id: 'header.en',
                    name: 'header.en',
                    type: 'text',
                    value: formik.values.header?.en,
                    onChange: formik.handleChange,
                    disabled: isViewOnly,
                  }}
                  addon={
                    <Tooltip placement="bottom" target="product-type-header-example">
                      <img src="/images/product/type/product-type-header-example.png" alt="Example" width={400} height={400} />
                    </Tooltip>
                  }
                  error={formik.touched.header?.en && formik.errors.header?.en ? formik.errors.header?.en : ''}
                />
              </Col>
              <Col>
                <CustomInput
                  label="Header De"
                  inputProps={{
                    id: 'header.de',
                    name: 'header.de',
                    type: 'text',
                    value: formik.values.header?.de,
                    onChange: formik.handleChange,
                    disabled: isViewOnly,
                  }}
                  addon={
                    <Tooltip placement="bottom" target="product-type-header-example">
                      <img src="/images/product/type/product-type-header-example.png" alt="Example" width={400} height={400} />
                    </Tooltip>
                  }
                  error={formik.touched.header?.de && formik.errors.header?.de ? formik.errors.header?.de : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomInput
                  label="Description En"
                  inputProps={{
                    id: 'description.en',
                    name: 'description.en',
                    type: 'textarea',
                    value: formik.values.description?.en,
                    onChange: formik.handleChange,
                    disabled: isViewOnly,
                  }}
                  addon={
                    <Tooltip placement="bottom" target="product-type-description-example">
                      <img src="/images/product/type/product-type-description-example.png" alt="Example" width={400} height={400} />
                    </Tooltip>
                  }
                  error={formik.touched.description?.en && formik.errors.description?.en ? formik.errors.description?.en : ''}
                />
              </Col>
              <Col>
                <CustomInput
                  label="Description De"
                  inputProps={{
                    id: 'description.de',
                    name: 'description.de',
                    type: 'textarea',
                    value: formik.values.description?.de,
                    onChange: formik.handleChange,
                    disabled: isViewOnly,
                  }}
                  addon={
                    <Tooltip placement="bottom" target="product-type-description-example">
                      <img src="/images/product/type/product-type-description-example.png" alt="Example" width={400} height={400} />
                    </Tooltip>
                  }
                  error={formik.touched.description?.de && formik.errors.description?.de ? formik.errors.description?.de : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomInput
                  label="Meta Description En"
                  inputProps={{
                    id: 'metadescription.en',
                    name: 'metadescription.en',
                    type: 'textarea',
                    value: formik.values.metadescription?.en,
                    onChange: formik.handleChange,
                    disabled: isViewOnly,
                  }}
                  addon={
                    <Tooltip placement="bottom" target="product-type-description-example">
                      <img src="/images/product/type/product-type-description-example.png" alt="Example" width={400} height={400} />
                    </Tooltip>
                  }
                  error={formik.touched.metadescription?.en && formik.errors.metadescription?.en ? formik.errors.metadescription?.en : ''}
                />
              </Col>
              <Col>
                <CustomInput
                  label="Meta Description De"
                  inputProps={{
                    id: 'metadescription.de',
                    name: 'metadescription.de',
                    type: 'textarea',
                    value: formik.values.metadescription?.de,
                    onChange: formik.handleChange,
                    disabled: isViewOnly,
                  }}
                  addon={
                    <Tooltip placement="bottom" target="product-type-description-example">
                      <img src="/images/product/type/product-type-description-example.png" alt="Example" width={400} height={400} />
                    </Tooltip>
                  }
                  error={formik.touched.metadescription?.de && formik.errors.metadescription?.de ? formik.errors.metadescription?.de : ''}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <CustomInput
                  label="Seo En"
                  inputProps={{
                    id: 'seo.en',
                    name: 'seo.en',
                    type: 'textarea',
                    value: formik.values.seo?.en,
                    onChange: formik.handleChange,
                    disabled: isViewOnly,
                  }}
                  addon={
                    <Tooltip placement="bottom" target="product-seo-slug-example">
                      <img src="/images/product/type/product-seo-slug-example.png" alt="Example" />
                    </Tooltip>
                  }
                  error={formik.touched.seo?.en && formik.errors.seo?.en ? formik.errors.seo?.en : ''}
                />
              </Col>
              <Col>
                <CustomInput
                  label="Seo De"
                  inputProps={{
                    id: 'seo.de',
                    name: 'seo.de',
                    type: 'textarea',
                    value: formik.values.seo?.de,
                    onChange: formik.handleChange,
                    disabled: isViewOnly,
                  }}
                  addon={
                    <Tooltip placement="bottom" target="product-seo-slug-example">
                      <img src="/images/product/type/product-seo-slug-example.png" alt="Example" />
                    </Tooltip>
                  }
                  error={formik.touched.seo?.de && formik.errors.seo?.de ? formik.errors.seo?.de : ''}
                />
              </Col>
            </Row>

            <Row>
              <IconsProductList setValues={formik.setValues} imageUrl={formik.values.imageUrl} />
            </Row>
            <Row>
              <CustomInput
                label="Image URL"
                inputProps={{
                  id: 'imageUrl',
                  name: 'imageUrl',
                  type: 'url',
                  value: formik.values.imageUrl,
                  onChange: formik.handleChange,
                  disabled: isViewOnly,
                }}
                addon={
                  <Tooltip placement="bottom" target="product-type-image-url-example">
                    <img src="/images/product/type/product-type-image-url-example.png" alt="Example" width={400} height={400} />
                  </Tooltip>
                }
                error={formik.touched.imageUrl && formik.errors.imageUrl ? formik.errors.imageUrl : ''}
              />
            </Row>
            <Col>
              <CustomInput
                label="Display Rank"
                inputProps={{
                  id: 'displayRank',
                  name: 'displayRank',
                  type: 'number',
                  value: formik.values?.displayRank,
                  onChange: formik.handleChange,
                  disabled: isViewOnly,
                }}
                error={formik.touched?.displayRank && formik.errors?.displayRank ? formik.errors?.displayRank : ''}
              />
            </Col>
            <Row>
              <Col>
                <p className="mb-2">Select a category for type</p>
                <List className="m-0 p-0">
                  {productCategories.map(({ _id, title }) => (
                    <ListInlineItem key={_id} className="mb-2">
                      <Button
                        className={`btn-md ${formik.values.category && _id === formik.values.category ? 'checked' : ''}`}
                        onClick={() => {
                          onProductCategorySelect(_id);
                        }}
                      >
                        {title?.de}
                      </Button>
                    </ListInlineItem>
                  ))}
                </List>
              </Col>
            </Row>
            {!isViewOnly && (
              <Row>
                <Button
                  className="btn-md"
                  disabled={loading}
                  onClick={() =>
                    onSubmit(formik.values, {
                      setSubmitting: formik.setSubmitting,
                    })
                  }
                >
                  {initialValues && initialValues._id ? 'Update' : 'Create'} Product Type {loading && <Spinner size="sm" color="secondary" />}
                </Button>
              </Row>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

type CreateProductTypeFormProps = {
  isViewOnly?: boolean;
  initialValues?: ProductType;
  productCategories: ProductCategory[];
  onCreation: (id: string) => void;
};

export { CreateProductTypeForm };
