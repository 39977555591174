import { Button, Col, FormFeedback, Label, ListInlineItem, Row } from 'reactstrap';
import { Times as TimesIcon } from '@styled-icons/typicons/Times';
import { CustomInput } from '../../../components/Input/CustomInput';
import { RepairStatus } from '../../../enums';
import { Product, Repair } from '../../../types';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import { Delete as DeleteIcon } from '@styled-icons/fluentui-system-filled/Delete';
import { resolveArrayItemKey } from '../../../utils';
import { OrderPictureUpload } from '../../../components/OrderPictureUpload/OrderPictureUpload';

const initialRepairItem: Partial<Repair> = { comment: '', inputValue: 0, status: RepairStatus.PENDING };
const RepairDetailsForm = ({
  index,
  product,
  selectedRepairs,
  removeRepair,
  formik,
  isViewOnly,
  arrayHelpers,
  repairList,
  clientName,
  creationTime,
}: RepairDetailsFormProps) => {
  const value = formik.values.repairList[index];
  const { name, productCategory, productType, priceCent, input, _id } = product;
  const isDuplicate = (productName: Product['name']) => {
    return selectedRepairs.filter(({ product }) => product.name === productName).length > 1;
  };

  const currentRepair = repairList[index];

  return (
    <ListInlineItem className="mb-4 gap-4 d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center">
        <p>Repair #{index + 1}</p>
        <div className="d-flex gap-2">
          {!isViewOnly && input && input?.type === 'LENGTH' && (
            <Button type="button" onClick={() => arrayHelpers.insert(index + 1, { product, ...initialRepairItem })}>
              Copy repair
            </Button>
          )}
          {!isViewOnly && isDuplicate(name) && (
            <Button type="button" color="danger" onClick={() => arrayHelpers.remove(index)}>
              <DeleteIcon className="icon danger" />
            </Button>
          )}
        </div>
      </div>
      {!isDuplicate(name) && (
        <div className="d-flex align-items-center justify-content-between gap-3">
          <div className="btn btn-secondary btn-md py-2 checked cursor-auto">
            {productCategory?.title?.de}, {productType?.title?.de}, {name?.de}, {priceCent} cent
          </div>
          <Button className="p-0 border-0" onClick={() => removeRepair(_id)} disabled={isViewOnly}>
            <TimesIcon width={24} />
          </Button>
        </div>
      )}
      {input?.type !== 'NO_INPUT' && (
        <CustomInput
          label={input ? `${input?.type}${input.unit ? `, ${input.unit}` : ''}*` : 'Product count*'}
          inputProps={{
            id: `repairList[${index}].inputValue`,
            name: `repairList[${index}].inputValue`,
            type: 'number',
            placeholder: `${input ? input.default : '0'}`,
            min: input?.type === 'QUANTITY' ? 1 : 0,
            step: input ? (input?.type === 'QUANTITY' ? 1 : 0.5) : 1,
            value: value.inputValue,
            onChange: formik.handleChange,
            disabled: isViewOnly || !selectedRepairs.some(({ product }) => product._id === _id.toLowerCase()),
          }}
          error={
            resolveArrayItemKey(`repairList[${index}].inputValue`, formik.touched) && resolveArrayItemKey(`repairList[${index}].inputValue`, formik.errors)
              ? resolveArrayItemKey(`repairList[${index}].inputValue`, formik.errors)
              : ''
          }
        />
      )}
      <CustomInput
        label="Comment"
        inputProps={{
          id: `repairList[${index}].comment`,
          name: `repairList[${index}].comment`,
          type: 'textarea',
          placeholder: 'Write here',
          value: value.comment,
          onChange: formik.handleChange,
          disabled: isViewOnly || !selectedRepairs.some(({ product }) => product._id === _id.toLowerCase()),
        }}
        error={
          resolveArrayItemKey(`repairList[${index}].comment`, formik.touched) && resolveArrayItemKey(`repairList[${index}].comment`, formik.errors)
            ? resolveArrayItemKey(`repairList[${index}].comment`, formik.errors)
            : ''
        }
      />
      <Row>
        <Col>
          <Label>Repair status*</Label>
          <ul className="m-0 p-0">
            {Object.values(RepairStatus).map((val) => (
              <ListInlineItem key={val} className="mb-2">
                <Button
                  color="secondary"
                  className={`btn-md ${value.status === val ? 'checked' : ''} ${
                    resolveArrayItemKey(`repairList[${index}].status`, formik.touched) && resolveArrayItemKey(`repairList[${index}].status`, formik.errors)
                      ? 'border-danger text-danger'
                      : ''
                  }`}
                  onClick={() => formik.setFieldValue(`repairList[${index}].status`, val)}
                  disabled={isViewOnly || !selectedRepairs.some(({ product }) => product._id === _id.toLowerCase())}
                >
                  {val}
                </Button>
              </ListInlineItem>
            ))}
          </ul>
          {!!resolveArrayItemKey(`repairList[${index}].status`, formik.touched) && resolveArrayItemKey(`repairList[${index}].status`, formik.errors) && (
            <FormFeedback className="d-block" invalid>
              {resolveArrayItemKey(`repairList[${index}].status`, formik.errors)?.toString()}
            </FormFeedback>
          )}
        </Col>
      </Row>
      <Row>
        <p style={{marginBottom: '5px'}}>Image Repair Upload</p>
        <OrderPictureUpload currentRepair={currentRepair} repairId={_id} clientName={clientName} creationTime={creationTime} />
      </Row>
    </ListInlineItem>
  );
};

type RepairDetailsFormProps = {
  index: number;
  product: Product;
  arrayHelpers: FieldArrayRenderProps;
  selectedRepairs: Repair[];
  removeRepair: (_id: Product['_id']) => void;
  formik: FormikProps<any>;
  isViewOnly: boolean;
  clientName: { name: string; lastName: string };
  creationTime: number;
  repairList: Repair[];
};

export { RepairDetailsForm };
