import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { apiRequest } from '../../utils/apiRequest';
import { getBase64 } from '../../utils/getBase64';
import { toastr } from 'react-redux-toastr';
import { Repair } from '../../types';
import ImageViewer from 'react-simple-image-viewer';
import { Button } from 'reactstrap';

const maxFileSize = 4096000;

interface OrderPictureUploadProps {
  clientName: { name: string; lastName: string };
  creationTime: number;
  currentRepair: Repair;
  repairId: string;
  setValues: any;
  values: any;
}

const OrderPictureUpload: React.FC<OrderPictureUploadProps> = ({ clientName, creationTime, setValues, currentRepair, values, repairId }) => {
  const [sizeError, setSizeError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState('');
  const { name, lastName } = clientName;
  const client = `${name}_${lastName}`;
  const { images = [] } = currentRepair || {};
  const [openImageWindow, setOpenImageWindow] = useState<boolean>(false);
  const creationTimeNew = Math.floor(Math.random() * 1000000);

  const openImageViewer = () => {
    setOpenImageWindow(true);
  };

  const onImageUpload = async (file: File, clientName: string, setUploading: (state: boolean) => void, creationTime: number) => {
    const abortController = new AbortController();
    try {
      setUploading(true);
      const base64File = (await getBase64(file)) as string;

      if (!base64File) {
        return toastr.error('Error', `Error while uploading ${file.name}`);
      }

      const { data } = await apiRequest(`/order/upload-image/${clientName}/${creationTime}`, {
        method: 'POST',
        data: JSON.stringify({ file: base64File, filename: file.name, filetype: file.type }),
        signal: abortController.signal,
      });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg));
        } else {
          toastr.error('Error', message);
        }
        return setUploading(false);
      }

      setUploading(false);
      toastr.success('Success', 'Image uploaded successfully');
    } catch (err) {
      console.error('UPLOAD_CRAFTSMAN_IMAGE error', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request');
      } else {
        toastr.error('Error', 'Failed to upload image');
      }
      abortController.abort();
      setUploading(false);
    }
  };
  const deleteImage = async (imageSrc: string) => {
    console.log('imageSrc', imageSrc);
  
    // Find the repair in the repairList that contains the image
    const updatedRepairList = values.repairList.map((repair: any) => {
      // Filter out the image from the repair's images list
      const updatedImages = repair.images.filter((src: any) => src !== imageSrc);
  
      return { ...repair, images: updatedImages };
    });
  
    console.log('Updated Repair List:', updatedRepairList);
  
    // Update the state with the updated repair list
    setValues({ ...values, repairList: updatedRepairList });
  
    // Now send the updated images list to the backend
    try {

  
    } catch (err) {
      console.error('Error updating images:', err);
      toastr.error('Error', 'Failed to update images');
    }
  };

  const onImageUploadUpdate = async (file: File, clientName: string, setUploading: (state: boolean) => void, creationTimeNew: number) => {
    const abortController = new AbortController();
    try {
      setUploading(true);
      const base64File = (await getBase64(file)) as string;

      if (!base64File) {
        return toastr.error('Error', `Error while uploading ${file.name}`);
      }

      const { data } = await apiRequest(`/order/update-image/${currentRepair._id}/${clientName}/${creationTimeNew}`, {
        method: 'PATCH',
        data: JSON.stringify({ file: base64File, filename: file.name, filetype: file.type }),
        signal: abortController.signal,
      });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg));
        } else {
          toastr.error('Error', message);
        }
        return setUploading(false);
      }

      setUploading(false);
      toastr.success('Success', 'Image uploaded successfully');
    } catch (err) {
      console.error('UPLOAD_CRAFTSMAN_IMAGE error', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request');
      } else {
        toastr.error('Error', 'Failed to upload image');
      }
      abortController.abort();
      setUploading(false);
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const fileUpload = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(fileUpload);
      if (fileUpload.size > maxFileSize) {
        setSizeError('File size exceeds 4 MB limit.');
        return;
      } else {
        setSizeError('');
      }

      await onImageUploadUpdate(fileUpload, client, setUploading, creationTimeNew);
    },
    [clientName, creationTimeNew]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <div {...getRootProps({ className: 'upload-zone' })}>
        <input {...getInputProps({ className: 'dropzone' })} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        {sizeError && <p style={{ color: 'red' }}>{sizeError}</p>}
        {uploading && <p>Uploading...</p>}
      </div>
      {image && <img src={image} style={{ width: '400px', height: '250px', marginTop: '12px' }} />}
      {images && images.length > 0 && (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '12px' }}>
          {images.map((imageSrc, index) => (
            <>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <img
                  key={index}
                  src={imageSrc}
                  alt="repair-picture"
                  style={{ width: '100%', height: '250px', margin: '10px', cursor: 'pointer' }}
                  onClick={openImageViewer}
                />
                <Button onClick={() => deleteImage(imageSrc)}>Delete</Button>
              </div>

              {openImageWindow && (
                <ImageViewer
                  backgroundStyle={{
                    backgroundColor: 'rgba(0,0,0 ,0.7)',
                    zIndex: '31',
                  }}
                  src={images as string[]}
                  onClose={() => setOpenImageWindow(false)}
                />
              )}
            </>
          ))}
        </div>
      )}
    </>
  );
};

export { OrderPictureUpload };
